<template>
  <Page title="Confirm registration">
    <div class="c-confirmRegister">
      <Typography
        v-text="$t('register.welcomeUserText')"
        variant="body1"
      />
      <BaseButton
        :btn-label="$t('login.loginBtnText')"
        size="small"
        variant="solid"
        color="primary"
        @click="handleClick"
      />
      <BaseModal
        size="small"
      >
        <template #body>
          <ActiveAuthForm />
        </template>
      </BaseModal>
    </div>
  </Page>
</template>

<script setup>
import { useStore } from 'vuex';

import Page from '@/components/Page.vue';
import BaseModal from '@/components/common/BaseModal.vue';
import ActiveAuthForm from '@/components/user/ActiveAuthForm.vue';

const { dispatch } = useStore();

const handleClick = () => {
  dispatch('overlays/setModal', true);
  dispatch('forms/setActiveForm', 'LoginForm');
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-confirmRegister {
  @include mx.d-flex(center, center, true);

  width: 100%;
  height: 80vh;
}
</style>
